import $ from "jquery";

var elements = document.querySelectorAll(".ce_rsce_text_image_slider");

if ($('.ce_rsce_text_image_slider .text-image-slider .text-image-slide').length == 1) {
    $('.ce_rsce_text_image_slider .text-image-slider .text-image-slide').clone().appendTo('.text-image-slider');
}
$('.ce_rsce_text_image_slider .text-image-slider').slick({
    autoplay: true,
    autoplaySpeed: 15000,
    infinite: true,
    prevArrow: '<div class="slick-prev"><img src="/files/themes/werbungaminn/images/icons/pfeil-links.svg" alt="Vorheriges Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-links-inset.png" alt="Vorheriges Element"></div>',
    nextArrow: '<div class="slick-next"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts.svg" alt="Nächstes Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts-inset.png" alt="Nächstes Element"></div>',
});


window.addEventListener("resize", function(){
    setTimeout(() => {
        elements.forEach(element => {
            updateButtonPosition(element);
        });
    }, 500);
});

function updateButtonPosition(element){
    var imageContainer = element.querySelector(".image-container").offsetHeight;
    var prev = element.querySelector(".slick-prev");
    var next = element.querySelector(".slick-next");
    prev.style.top = imageContainer + "px";
    next.style.top = imageContainer + "px";
}
