var doc = document;
var body = doc.body;

var lightboxGroups = {};

if(doc.querySelector("a[data-lightbox]")){
    doc.querySelectorAll("a[data-lightbox]").forEach(link => {
        var href = link.href + "#" + Math.random();
        var lightboxGroup = link.dataset.lightbox;
        if(lightboxGroup in lightboxGroups == false){
            lightboxGroups[lightboxGroup] = [];
        }
        lightboxGroups[lightboxGroup].push(href);
        link.addEventListener("click", e => {
            e.preventDefault();
            if(!window.drag){
                openLightbox(href, lightboxGroup);
            }
        });
    });
}

doc.addEventListener('mousedown', () => window.drag = false);
doc.addEventListener('mousemove', () => window.drag = true);

function openLightbox(imgLink, lightboxGroup){
    var elements = {};
    var divs = ['lbBg', 'lb', 'cB', 'pB', 'nB', 'lBg', 'l', 'lbIC'];
    for (const div in divs) {
        elements[divs[div]] = doc.createElement('div');
    }

    var lightboxBackground = elements.lbBg;
    var lightbox = elements.lb;
    var lightboxImageContainer = elements.lbIC;
    var image = doc.createElement('img');
    var closeButton = elements.cB;
    var prevButton = elements.pB;
    var nextButton = elements.nB;
    var loaderBackground = elements.lBg;
    var loader = elements.l;

    lightboxBackground.classList.add("lb-bg");
    lightboxBackground.id = "lb-bg";

    lightbox.classList.add("lb");
    lightbox.id = "lb";

    lightboxImageContainer.classList.add("lb-img-cnt");
    lightboxImageContainer.id = "lb-img-cnt";

    image.src = imgLink;
    image.addEventListener("load", () => {
        setTimeout(() => {
            if(doc.documentElement.classList.contains('touch')){
                doc.querySelector("#lb-img-cnt img").style.transition = "opacity linear .2s";
                doc.querySelector("#lb-img-cnt img").style.opacity = "1";
                setImageTransform("0");
                setTimeout(() => {
                    doc.querySelector("#lb-img-cnt img").style.transition = "transform cubic-bezier(0,0,.22,1) .1s, opacity linear .2s";
                }, 100);
            }
            doc.querySelector("#lb-load-bg").style.opacity = 0;
        }, 100);
    });
    if(doc.documentElement.classList.contains('touch')){
        lightbox.addEventListener("touchstart", e => {
            imageTouchStart(e);
        });
        lightbox.addEventListener("touchmove", e => {
            imageTouchMove(e);
        });
        lightbox.addEventListener("touchend", e => {
            imageTouchEnd(e, lightboxGroup);
        });
    }

    closeButton.classList.add("lb-close");
    closeButton.addEventListener("click", () => {
        destroyLightbox();
    });

    prevButton.classList.add("lb-prev");
    prevButton.addEventListener("click", () => {
        previousImage(lightboxGroup);
    });

    nextButton.classList.add("lb-next");
    nextButton.addEventListener("click", () => {
        nextImage(lightboxGroup);
    });

    loaderBackground.classList.add("lb-load-bg");
    loaderBackground.id = "lb-load-bg";

    loader.classList.add("lb-load");
    loader.id = "lb-load";

    lightboxImageContainer.append(image);
    lightbox.append(lightboxImageContainer);
    lightbox.append(closeButton);
    lightbox.append(prevButton);
    lightbox.append(nextButton);
    lightbox.append(loaderBackground);
    loaderBackground.append(loader);
    body.appendChild(lightboxBackground);
    body.appendChild(lightbox);

    setTimeout(() => {
        lightbox.style.opacity = "1";
        lightboxBackground.style.opacity = "1";
        lightboxBackground.style.backdropFilter = "blur(5px)";
        lightboxBackground.style.webkitBackdropFilter = "blur(5px)";
        body.onclick = (e) => {
            if(doc.querySelector(".lb")){
                var target = e.target;
                if(target.id != 'lb-load-bg' 
                    && !target.classList.contains("lb-prev") 
                    && !target.classList.contains("lb-next")
                    && !target.classList.contains("lb")) {
                    destroyLightbox();
                }
            }
        }
    }, 200);

    var image = findCurrentImage(lightbox, lightboxGroups[lightboxGroup]);
    if(image == 0){
        prevButton.style.display = "none";
    }
    if(image + 1 == lightboxGroups[lightboxGroup].length){
        nextButton.style.display = "none";
    }

    body.classList.add("dont-scroll");
    registerKeyEvents(lightboxGroups, lightboxGroup)
}

var touchPos;
function imageTouchStart(e){
    if(e.touches.length == 1){
        touchPos = e.changedTouches[0].clientX;
    }
}
function imageTouchMove(e){
    if(touchPos != null){
        var currentPos = e.changedTouches[0].clientX;
        var distance = currentPos - touchPos;
        setImageTransform(distance + "px");
    }
}
function imageTouchEnd(e, lightboxGroup){
    if(touchPos != null){
        var finalPos = e.changedTouches[0].clientX;
        var lightbox = doc.querySelector("#lb");
        var images = lightboxGroups[lightboxGroup];
        var image = findCurrentImage(lightbox, images);
        if(finalPos > touchPos + 25 && image > 0){
            previousImage(lightboxGroup);
        }else if(finalPos + 25 < touchPos && image < images.length - 1){
            nextImage(lightboxGroup);
        }else{
            setImageTransform("0");
        }
        touchPos = null;
    }
}

function destroyLightbox(){
    doc.querySelector("#lb").remove();
    doc.querySelector("#lb-bg").remove();
    body.classList.remove("dont-scroll");
    body.onclick = () => {};
    doc.onkeydown = () => {};
}

function previousImage(lightboxGroup){
    var lightbox = doc.querySelector("#lb");
    var images = lightboxGroups[lightboxGroup];

    if(doc.documentElement.classList.contains('touch')){
        setImageTransform("100vw");
        doc.querySelector('#lb-img-cnt img').style.opacity = "0";
    }
    doc.querySelector("#lb-load-bg").style.opacity = "1";
    lightbox.querySelector(".lb-next").style.display = "block";

    var image = findCurrentImage(lightbox, images);
    
    lightbox.querySelector("img").src = "";
    lightbox.querySelector("img").src = images[image - 1];

    if(image - 1 == 0){
        lightbox.querySelector(".lb-prev").style.display = "none";
    }
}

function nextImage(lightboxGroup){
    var lightbox = doc.querySelector("#lb");
    var images = lightboxGroups[lightboxGroup];

    if(doc.documentElement.classList.contains('touch')){
        setImageTransform("-100vw");
        doc.querySelector('#lb-img-cnt img').style.opacity = "0";
    }
    doc.querySelector("#lb-load-bg").style.opacity = "1";
    lightbox.querySelector(".lb-prev").style.display = "block";

    var image = findCurrentImage(lightbox, images);

    lightbox.querySelector("img").src = "";
    lightbox.querySelector("img").src = images[image + 1];

    if(image + 1 >= images.length - 1){
        lightbox.querySelector(".lb-next").style.display = "none";
    }
}

function findCurrentImage(lightbox, images){
    var i = 0;
    for (const image in images) {
        if(images[image] == lightbox.querySelector("img").src){
            break;
        }
        i++;
    }
    return i;
}

function registerKeyEvents(lightboxGroups, lightboxGroup){
    var lightbox = doc.querySelector("#lb");
    var images = lightboxGroups[lightboxGroup];

    doc.onkeydown = (event) => {
        var image = findCurrentImage(lightbox, images);
        switch (event.key) {
            case "ArrowLeft":
            case "Left":
                if(image <= 0) break;
                previousImage(lightboxGroup);
                break;
            case "ArrowRight":
            case "Right":
                if(image == images.length - 1) break;
                nextImage(lightboxGroup);
                break;
            case "Escape":
            case "Esc":
                destroyLightbox();
                break;
        }
    };
}

function setImageTransform(value){
    doc.querySelector('#lb-img-cnt img').style.transform = "translateX(" + value + ")";
}
