if(document.querySelector(".ce_rsce_referenzen")){
    var tags = document.querySelector(".ce_rsce_referenzen").querySelectorAll(".filter ul li.filter > span");
    var subfilter = document.querySelector(".ce_rsce_referenzen").querySelectorAll(".filter ul li.subfilter");
    tags.forEach(tag => {
        tag.addEventListener("click", function(){
            var images = document.querySelector(".ce_rsce_referenzen .masonry").querySelectorAll(".image-container");
            images.forEach(image => {
                if(tag.parentElement.dataset.filter == "all"){
                        image.style.height = "initial";
                        image.classList.add("active");
                }else{
                    if(image.dataset.tags.includes(tag.parentElement.dataset.filter)){
                        image.style.height = "initial";
                        image.classList.add("active");
                    }else{
                        image.style.height = "0px";
                        image.classList.remove("active");
                    }
                }
            });
            tags.forEach(allTags => {
                allTags.parentElement.classList.remove("active");
            });
            subfilter.forEach(allTags => {
                allTags.parentElement.classList.remove("active");
            });
            tag.parentElement.classList.add("active");
        });
    });
    subfilter.forEach(sf => {
        sf.addEventListener("click", function(){
            var images = document.querySelector(".ce_rsce_referenzen .masonry").querySelectorAll(".image-container");
            images.forEach(image => {
                if(image.dataset.tags.includes(sf.dataset.filter + ";" + sf.dataset.subfilter)){
                    image.style.height = "initial";
                    image.classList.add("active");
                }else{
                    image.style.height = "0px";
                    image.classList.remove("active");
                }
            });
            tags.forEach(allTags => {
                allTags.parentElement.classList.remove("active");
            });
            subfilter.forEach(allTags => {
                allTags.parentElement.classList.remove("active");
            });
            sf.classList.add("active");
        });
    });
}