setInterval(() => {
    updateBoxHeight();
}, 500);

window.addEventListener("resize", function(){
    updateBoxHeight();
});

function updateBoxHeight(){
    var containers = document.getElementsByClassName("same-height");
    for(var i = 0; i < containers.length; i++){
        var items = containers[i].getElementsByClassName("same-height-item");
        for(var j = 0; j < items.length; j++){
            var item = items[j];
            item.style.height = "auto";
        }
    }
    for(var i = 0; i < containers.length; i++){
        var height = 0;
        var items = containers[i].getElementsByClassName("same-height-item");
        for(var j = 0; j < items.length; j++){
            var item = items[j];
            if(containers[i].classList.contains("shrink")){
                if(height > item.clientHeight || height == 0){
                    height = item.clientHeight;
                }
            }else{
                if(height < item.clientHeight){
                    height = item.clientHeight;
                }
            }
        }
        for(var j = 0; j < items.length; j++){
            var item = items[j];
            if((containers[i].classList.contains("desktop-only") && window.innerWidth > 768) || !containers[i].classList.contains("desktop-only")) {
                item.style.height = height + "px";
            }
        }
    }
}
