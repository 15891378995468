var Masonry = require('./masonry-layout.js');

if(document.querySelector(".ce_rsce_referenzen  .grid")){
    setTimeout(() => {
        var msnry = new Masonry('.ce_rsce_referenzen .grid', { 
            itemSelector: ".grid-item",
            gutter: 25
        });
        setInterval(() => {
            msnry.layout();
        }, 500);
    }, 1000);
}

if(document.querySelector(".ce_rsce_gallery .grid")){
    setTimeout(() => {
        document.querySelectorAll(".ce_rsce_gallery .grid").forEach(element => {
            var msnry = new Masonry(element, { 
                itemSelector: ".grid-item",
                gutter: 25
            });
            setInterval(() => {
                msnry.layout();
            }, 500);
        });
    }, 1000);
}

