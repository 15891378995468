import $ from "jquery";
require("slick-carousel");

$('.ce_rsce_image_slider .image-slider').slick({
  dots: true,
  autoplay: true,
  infinite: false,
  autoplaySpeed: 7500,
  arrows: false,
  prevArrow: '<div class="slick-prev"><img src="/files/themes/werbungaminn/images/icons/pfeil-links.svg" alt="Vorheriges Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-links-inset.png" alt="Vorheriges Element"></div>',
  nextArrow: '<div class="slick-next"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts.svg" alt="Nächstes Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts-inset.png" alt="Nächstes Element"></div>',
});

$('.ce_rsce_kunden .kunden').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7500,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ],
  prevArrow: '<div class="slick-prev"><img src="/files/themes/werbungaminn/images/icons/pfeil-links.svg" alt="Vorheriges Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-links-inset.png" alt="Vorheriges Element"></div>',
  nextArrow: '<div class="slick-next"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts.svg" alt="Nächstes Element"><img src="/files/themes/werbungaminn/images/icons/pfeil-rechts-inset.png" alt="Nächstes Element"></div>',
});