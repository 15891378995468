import $ from "jquery";

if(document.querySelectorAll(".mod_article > * > .inside, .mod_article > * > * > .inside").length > 0){
    setTimeout(() => {
        checkVisibility();
    },500);

    window.addEventListener('scroll', function(e) {
        checkVisibility();
    }, {passive: true});

    function checkVisibility(){
        document.querySelectorAll(".mod_article > * > .inside:not(.is-visible), .mod_article > * > * > .inside:not(.is-visible)").forEach(function(element) {
            if(isElementXPercentInViewport(element, 10)) {
                element.classList.add("visible");
            }
        });
    }

    function isElementXPercentInViewport(el, percentVisible) {
        let rect = el.getBoundingClientRect(),
            windowHeight = window.innerHeight;

        return !(
            Math.floor(100 - ((rect.top / -rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        );
    }
}

$(".ce_rsce_contact_sidebar").addClass("visible");