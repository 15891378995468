document.querySelector(".hamburger").addEventListener("click", toggleNavMenu);

function toggleNavMenu(){
    var nav = document.querySelector("header nav");
    if(nav.classList.contains("active")){
        nav.classList.remove("active");
        document.querySelector(".hamburger").classList.remove("active");
        document.querySelector("header").classList.remove("active");
    }else{
        nav.classList.add("active");
        document.querySelector(".hamburger").classList.add("active");
        document.querySelector("header").classList.add("active");
    }
}