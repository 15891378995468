import $ from "jquery";

$(window).on("scroll", function() {
    if ($(window).scrollTop() > 30) {
        document.body.classList.add("scrolled");
    }else {
        document.body.classList.remove("scrolled");
    }
    if ($(window).scrollTop() > 200) {
        document.body.classList.add("scrolled-far");
    }else {
        document.body.classList.remove("scrolled-far");
    }
});